import React, { useState } from 'react';
import { useObserver } from '../../../../hooks/useObserver';

const NavLink = ({ label, scrollTo, to }) => {
  const [isActive, setIsActive] = useState(false);
  useObserver(`[name=${to}]`, true, setIsActive);
  return (
    <li
      className={
        to !== 'partners' ? 'nav-item' : ' nav-item not-mobile-nav-item'
      }
      key={label}
      onClick={scrollTo}
    >
      <span className={isActive ? 'nav-link active' : 'nav-link'}>{label}</span>
    </li>
  );
};

export default NavLink;
