import React from 'react';
import { contacts } from '../../../constants';
import SectionHeader from '../sectionHeader';

const Contacts = () => {
  const { address, phone, email, map } = contacts;
  return (
    <>
      <SectionHeader>Контакты</SectionHeader>
      <div className="row align-items-center justify-content-center p-2">
        <div className="section-subheading  mb-5">
          <i className="fa fa-map-pin" aria-hidden="true"></i>
          <strong> &nbsp;Адрес: </strong>&nbsp;{address}
          <br />
          <i className="fa fa-phone" aria-hidden="true"></i>
          <strong>&nbsp;Тел:</strong>
          &nbsp;{phone}
          <br />
          <i className="fa fa-envelope" aria-hidden="true"></i>
          <strong>&nbsp;E-mail:</strong>&nbsp;
          <a href={`mailto:${email}`}>{email}</a>
        </div>
        <div className="map-responsive">
          <iframe
            src={map}
            width="100%"
            height="500px"
            frameBorder="0"
            title="gglemaps"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Contacts;
