import logo_bns from "../resources/img/logos/logo_bns.png";
import logo_task from "../resources/img/logos/logo_task.png";
import logo_btib from "../resources/img/logos/logo_btib.png";
import logo_euroins from "../resources/img/logos/logo_euroins.svg";
import logo_hoster from "../resources/img/logos/logo_hoster.png";
import logo_erip from "../resources/img/logos/logo_erip.png";
import elpoF from "../resources/img/portfolio/elpoF.png";
import policyF from "../resources/img/portfolio/policyF.png";
import armF from "../resources/img/portfolio/armF.png";
import go2016F from "../resources/img/portfolio/go2016F.png";
import dsF from "../resources/img/portfolio/dsF.png";
import elpoB from "../resources/img/portfolio/elpoB.png";
import policyB from "../resources/img/portfolio/policyB.png";
import armB from "../resources/img/portfolio/armB.png";
import go2016B from "../resources/img/portfolio/go2016B.png";
import dsB from "../resources/img/portfolio/dsB.png";
import ellipsis from "../resources/img/portfolio/_ellipsis.png";
import justPayDoc1 from "../resources/doc/Общие условия участия.docx";
import justPayDoc2 from "../resources/doc/Договор присоединения типовой ПУ к Justpay.docx";

export const doc1 = justPayDoc1;
export const doc2 = justPayDoc2;

export const partners = [
  {
    image: logo_bns,
    title: "ЗАСО Белнефтестрах",
    alt: "БНС",
    link: "http://www.bns.by",
  },
  {
    image: logo_task,
    title: "ЗАСО ТАСК",
    alt: "ТАСК",
    link: "http://www.task.by",
  },
  {
    image: logo_btib,
    title: "Белорусское бюро по транспортному страхованию",
    alt: "ББТС",
    link: "http://www.btib.org/",
  },
  {
    image: logo_euroins,
    title: "ЕВРОИНС Страховая компания",
    alt: "ЕВРОИНС",
    link: "http://euroins.by",
  },
  {
    image: logo_hoster,
    title: "ХОСТИНГ И ОБЛАЧНЫЕ РЕШЕНИЯ",
    alt: "Hoster.by",
    link: "https://hoster.by/",
  },
  {
    image: logo_erip,
    title:
      "Открытое акционерное общество ”Небанковская кредитно-финансовая организация ”Единое расчетное и информационное пространство“",
    alt: "ЕРИП",
    link: "https://www.raschet.by/",
  },
];

export const portfolio = [
  {
    image: elpoF,
    label: "Электронный полис ОСГО",
    subLabel: "Заключение договоров ОСГО online",
    imageB: elpoB,
    body: `<p>
    Оформление договора обязательного страхования гражданской
    ответственности владельцев транспортных средств (ОСГО) в
    электронном виде.
  </p>
  <p>
    Продукт разработан в 2019 году для Белорусского бюро по
    транспортному страхованию.
  </p>`,
  },
  {
    image: policyF,
    label: "САСД ПОЛИС",
    subLabel: "Система автоматизации страховой деятельности",
    imageB: policyB,
    body: `<ul class="desc-list">
      Многофункциональная система для решения задач сотрудников
      страховой организации:
      <li>широкая настройка правил страхования;</li>
      <li>
        заведение и редактирование договоров прямого страхования;
      </li>
      <li>ведение договоров сострахования, перестрахования;</li>
      <li>работа с убытками;</li>
      <li>расчёт резервов;</li>
      <li>расчёт комиссионного вознаграждения агентов;</li>
      <li>
        интеграция с внешними системами, такими как "ЕРИП", АИС
        "ГСР", "ББТС" и т.д;
      </li>
      <li>
        загрузка и выгрузка данных в разных форматах для других
        систем (Альфа-Банк, Белгазпромбанк, Белинвестбанк, Банк
        ВТБ, Приорбанк, ...).
      </li>
    </ul>
    <p>Система разработана в 2000 году для страховых компаний.</p>`,
  },
  {
    image: armF,
    label: "АРМ агента",
    subLabel: "Система автоматизации страховой деятельности агента",
    imageB: armB,
    body: `<p>
    Веб-система "АРМ Агента" позволяет ускорить процесс выдачи и
    печати нового договора страхования.
  </p>
  <p>
    Продукт разработан в 2010 году для агентов страховых
    компаний.
  </p>`,
  },
  {
    image: go2016F,
    label: "ГО-2016",
    subLabel: "Информационно-справочная система",
    imageB: go2016B,
    body: `<p>
    Информационная система «ГО-2016» предназначена для загрузки
    информации о договорах страхования, страховых случаях,
    оплате по договорам и выплатам по страховым случаям,
    представленной в определенных и заранее описанных форматах в
    оперативные таблицы системы «ГО-2016». Это информация по
    обязательному страхованию гражданской ответственности,
    комплексному страхованию владельцев транспортных средств и
    белорусским сертификатам «Зеленая карта».
  </p>
  <p>
    Система разработана в 2016 году для Белорусского бюро по
    транспортному страхованию.
  </p>`,
  },
  {
    image: dsF,
    label: "Автошкола-Контроль",
    subLabel: "Облачный сервис для комплексной автоматизации автошколы",
    imageB: dsB,
    body: ` <ul class="desc-list">
    К основным особенностям облачного сервиса для комплексной
    автоматизации автошколы «Автошкола контроль» можно отнести:
    <li>
      CRM-систему, которая позволяет наладить работу с
      потенциальными и текущими клиентами посредством учета
      обращений и постановки задач;
    </li>
    <li>
      учебный модуль, содержащий экзаменационные билеты по ПДД,
      электронный журнал, расписание занятий и другие
      инструменты, позволяющие наладить процесс дистанционного
      обучения;
    </li>
    <li>
      автоматизацию документооборота на основе использования
      унифицированных шаблонов документов и информации из
      сервиса вносимый всего один раз;
    </li>
    <li>
      современную коммуникацию с клиентами и персоналом
      посредством SMS, email, WhatsApp и PUSH-сообщений;
    </li>
    <li>
      финансовый блок, позволяющий осуществлять продажу услуг,
      контроль оплат и задолженностей, работу с банковскими
      выписками и расчеты с сотрудниками;
    </li>
    <li>
      блок занятий вождения, включающий расписание, работу с
      путевым листами и табелями, распределением нагрузки на
      мастеров, мониторинг транспорта и анализ статистических
      данных;
    </li>
    <li>
      современное приложение на базе OC Android и IOS позволяет
      наладить коммуникацию между мастерами ПОВ, курсантами и
      администрацией автошколы. Приложение позволяет
      организовать не только вождение, дистанционное обучение
      теории и контроль знаний, но и отображение оплат и
      задолженностей по договору курсанта;
    </li>
    <li>
      модуль онлайн-обучения позволяет использовать различные
      методики обучения, включая видео-курс «Рули-онлайн».
    </li>
  </ul>
  <p>Система разработана в 2019 году для автошкол.</p>`,
  },
  {
    image: ellipsis,
    label: "Другие проекты",
    subLabel: "",
    imageB: null,
    body: `<ul class="desc-list">
    <li>
      АИС "Обязательное страхование гражданской ответственности
      владельцев транспортных средств"
    </li>
    <li>
      Система организации страховой деятельности брокера
      (БРОКЕР)
    </li>
    <li>
      Автоматизированная система управления предприятием оптовой
      торговли (АКВАРИУС)
    </li>
    <li>Система ведения и сопровождения договоров (ЭСКОРТ)</li>
    <li>
      Автоматизированная система контроля качества воды (H2O)
    </li>
    <li>
      Автоматизированная система управления персоналом (КАДРЫ)
    </li>
  </ul>`,
  },
];

export const services = [
  {
    icon: "fa-shopping-cart",
    label: "Поставка проектов собственной разработки",
  },
  {
    icon: "fa-laptop",
    label: "Проектирование информационных систем управления бизнесом под заказ",
  },
  {
    icon: "fa-database",
    label: "Консалтинг и экспертиза в области информационных технологий",
  },
];

export const contacts = {
  address: "Минск, 4-й Загородный переулок, 58/Б, офис 15, (индекс 220073)",
  phone: "(+375-17) 368-24-25, 363-24-25, 367-52-45",
  email: "daa@globocenter.by",
  map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2350.380686091288!2d27.50803291599262!3d53.90721084025259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dbc55a1441461b%3A0xc481d2dee3fa69e0!2zNC3QuSDQl9Cw0LPQvtGA0L7QtNC90YvQuSDQv9C10YAuIDU40LEsINCc0LjQvdGB0Lo!5e0!3m2!1sru!2sby!4v1581083557215!5m2!1sru!2sby",
};
