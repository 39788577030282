import React, { useState } from 'react';
import { scroller } from 'react-scroll';
import { useScrollYAchievement } from '../../../hooks/useScrollYAchievement';
import logo from '../../../resources/img/logos/logo.svg';
import { scrollToElement } from '../../../utils';
import NavLink from './navLink/NavLink';

const navLinks = [
  { to: 'services', label: 'Услуги' },
  { to: 'company', label: 'О компании' },
  { to: 'justPay', label: 'Подключение к ЕРИП' },
  { to: 'partners', label: 'Партнеры' },
  { to: 'contacts', label: 'Контакты' },
];

const Navbar = () => {
  const navShow = useScrollYAchievement(100);
  const [isCollapseMenu, setIsCollapseMenu] = useState(false);

  let navClasses = ['navbar', 'navbar-expand-lg', 'navbar-dark', 'fixed-top'];
  let logoClasses = ['img-fluid', 'mx-auto', 'logo-hidden'];

  if (navShow) {
    navClasses = [...navClasses, 'navbar-shrink'];
    logoClasses = [...logoClasses.slice(0, -1)];
  }

  const toggleMenu = () => {
    return isCollapseMenu ? setIsCollapseMenu(false) : setIsCollapseMenu(true);
  };

  return (
    <nav className={navClasses.join(' ')} id="mainNav">
      <div className="container">
        <div
          className="navbar-brand"
          onClick={() => scrollToElement(scroller, 'header')}
        >
          <img
            id="logo"
            className={logoClasses.join(' ')}
            src={logo}
            alt="Глобо-Центр"
            title="Глобо-Центр"
          />
        </div>
        <button
          className={
            isCollapseMenu
              ? `navbar-toggler navbar-toggler-right`
              : `navbar-toggler navbar-toggler-right collapsed`
          }
          type="button"
          aria-controls="navbarResponsive"
          aria-label="Toggle navigation"
          onClick={() => {
            toggleMenu();
          }}
        >
          Меню
          <i className="fas fa-bars"></i>
        </button>

        <div
          className={
            isCollapseMenu
              ? `${'collapsing navbar-collapse'}`
              : `${'collapse navbar-collapse'}`
          }
          id="navbarResponsive"
          style={isCollapseMenu ? { height: '273px' } : {}}
        >
          <ul className="navbar-nav text-uppercase ml-auto">
            {navLinks.map(({ to, label }) => {
              return (
                <NavLink
                  label={label}
                  key={label}
                  to={to}
                  scrollTo={() => {
                    scrollToElement(scroller, to);
                    setIsCollapseMenu(false);
                  }}
                />
              );
            })}
            <li className="nav-item">
              <a
                className="nav-link"
                href="http://globocenter.by/ords/f?p=100"
                target="_blank"
                rel="noreferrer"
              >
                Служба поддержки
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
