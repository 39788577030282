import React, { useState } from 'react';
import { portfolio } from '../../../constants';
import SectionHeader from '../sectionHeader';
import Card from './card';

const Portfolio = () => {
  const [showBackDrop, setShowBackDrop] = useState(false);

  return (
    <>
      <SectionHeader>Проекты</SectionHeader>
      <div className="row">
        {portfolio.map(({ ...props }, idx) => {
          return (
            <Card
              {...props}
              key={props.label}
              setShowBackDrop={setShowBackDrop}
              idx={idx + 1}
            />
          );
        })}
      </div>
      <div
        className={showBackDrop ? 'modal-backdrop fade show' : 'd-none'}
      ></div>
    </>
  );
};

export default Portfolio;
