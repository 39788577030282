import React from 'react';
import { partners } from '../../../constants';
import SectionHeader from '../sectionHeader';
import { Slide } from 'react-awesome-reveal';
import Card from './card';

const Partners = () => {
  return (
    <>
      <SectionHeader>Наши партнеры</SectionHeader>
      <Slide triggerOnce>
        <div className="row justify-content-center">
          {partners.map(({ ...props }) => {
            return <Card {...props} key={props.title} />;
          })}
        </div>
      </Slide>
    </>
  );
};

export default Partners;
