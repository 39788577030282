import React from 'react';
import Company from './company';
import Contacts from './contacts';
import JustPay from './justPay';
import Partners from './partners';
import Portfolio from './portfolio';
import Section from './section';
import Services from './services';
import { Element } from 'react-scroll';

const sections = [
  { name: 'services', component: <Services />, bgColor: '' },
  { name: 'company', component: <Company />, bgColor: 'bg-light' },
  { name: 'justPay', component: <JustPay />, bgColor: '' },
  { name: 'portfolio', component: <Portfolio />, bgColor: 'bg-light' },
  { name: 'partners', component: <Partners />, bgColor: '' },
  { name: 'contacts', component: <Contacts />, bgColor: 'bg-light' },
];

const PageSections = () => {
  return (
    <>
      {sections.map(({ name, component, bgColor }) => {
        return (
          <Element name={name} key={name}>
            <Section name={name} bgColor={bgColor}>
              {component}
            </Section>
          </Element>
        );
      })}
    </>
  );
};

export default PageSections;
