import React, { useEffect } from 'react';
import {
  addModalOverflowBodyHidden,
  clearModalOverflowBodyHidden,
} from '../../../utils';

const Modal = ({
  body,
  label,
  subLabel,
  image,
  visible,
  setVisible,
  setShowBackDrop,
}) => {
  let modalClasses = ['portfolio-modal', 'modal', 'fade'];
  let modalStyle = { display: 'none' };
  if (visible) {
    modalClasses = [...modalClasses, 'show'];
    modalStyle = { display: 'block', paddingRight: '15px' };
  }
  const closeModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      addModalOverflowBodyHidden();
      setShowBackDrop(true);
    }
    return () => {
      clearModalOverflowBodyHidden();
      setShowBackDrop(false);
    };
  }, [visible, setShowBackDrop]);

  return (
    <div
      className={modalClasses.join(' ')}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={modalStyle}
      onClick={closeModal}
    >
      <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <div
            className="close-modal"
            data-dismiss="modal"
            onClick={closeModal}
          >
            <div className="lr">
              <div className="rl"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="modal-body">
                  <h2 className="text-uppercase">{label}</h2>
                  <p className="item-intro text-muted">{subLabel}</p>
                  {image && (
                    <img
                      className="img-fluid d-block mx-auto"
                      src={image}
                      alt="screen"
                    />
                  )}
                  <div dangerouslySetInnerHTML={{ __html: body }}></div>
                  <button
                    className="btn btn-primary"
                    data-dismiss="modal"
                    type="button"
                    onClick={closeModal}
                  >
                    Закрыть
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Modal);
