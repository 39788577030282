import React from 'react';
import Navbar from './navbar';
import { scroller } from 'react-scroll';
import { scrollToElement } from '../../utils';

const Header = () => {
  return (
    <>
      <Navbar />
      <header className="masthead">
        <div className="container">
          <div className="intro-text">
            <div className="intro-lead-in">
              Частное унитарное предприятие по оказанию услуг
            </div>
            <div className="intro-heading text-uppercase">"Глобо-Центр"</div>
            <div className="intro-lead-in">
              Информационные технологии и системы
            </div>
            <span
              className="btn btn-primary btn-xl text-uppercase"
              onClick={() => scrollToElement(scroller, 'services')}
            >
              Узнать больше
            </span>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
