import React from 'react';

const Card = ({ image, title, alt, link }) => {
  return (
    <div className="col-md-2 col-sm-6">
      <a href={link} target="_blank" rel="noreferrer">
        <img
          className="img-fluid d-block mx-auto"
          src={image}
          alt={alt}
          title={title}
        />
      </a>
    </div>
  );
};

export default Card;
