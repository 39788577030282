import { useEffect, useRef } from 'react';

export const useObserver = (to, canLoad, callback) => {
  const observer = useRef();

  useEffect(() => {
    const element = document.querySelector(to);
    if (element) {
      if (observer.current) observer.current.disconnect();

      const cb = function (entries, observer) {
        if (entries[0].isIntersecting && canLoad) {
          callback(true);
        } else {
          callback(false);
        }
      };
      observer.current = new IntersectionObserver(cb, { threshold: 1.0 });
      observer.current.observe(element);
    }
  }, [to, canLoad, callback]);
};
