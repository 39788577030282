import React from 'react';

const SectionHeader = ({ children }) => {
  return (
    <div className="row">
      <div className="col-lg-12 text-center">
        <h2 className="section-heading text-uppercase">{children}</h2>
      </div>
    </div>
  );
};

export default SectionHeader;
