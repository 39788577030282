import React, { useState } from 'react';
import Modal from '../../modal';
import { Fade } from 'react-awesome-reveal';

const Card = ({
  image,
  label,
  subLabel,
  imageB,
  body,
  setShowBackDrop,
  idx,
}) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      <div className="col-md-4 col-sm-6 portfolio-item">
        <Fade triggerOnce delay={idx * 0.3 * 1000}>
          <div
            className="portfolio-link"
            onClick={(e) => {
              e.preventDefault();
              setModal(true);
            }}
          >
            <div className="portfolio-hover">
              <div className="portfolio-hover-content">
                <i className="fas fa-search fa-3x"></i>
              </div>
            </div>

            <img className="img-fluid" src={image} alt="portfolio" />
          </div>
          <div className="portfolio-caption">
            <h4>{label}</h4>
            <p className="text-muted">{subLabel}</p>
          </div>
        </Fade>
      </div>
      <Modal
        visible={modal}
        setVisible={setModal}
        label={label}
        subLabel={subLabel}
        image={imageB}
        body={body}
        setShowBackDrop={setShowBackDrop}
      ></Modal>
    </>
  );
};

export default React.memo(Card);
