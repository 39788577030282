import { useEffect, useState } from 'react';

export const useScrollYAchievement = (scroll = 200) => {
  const [achieve, setAchieve] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > scroll) {
      setAchieve(true);
    } else {
      setAchieve(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return achieve;
};
