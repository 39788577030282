import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12">
            <span className="copyright">&copy; Глобо-Центр</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
