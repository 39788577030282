import React from "react";
import { doc1, doc2 } from "../../../constants";
import SectionHeader from "../sectionHeader";

const JustPay = () => {
  return (
    <>
      <SectionHeader>JustPay - Агрегатор в ЕРИП (АИС "Расчет") </SectionHeader>
      <div className="row">
        <div className="col-lg-12" id="about_us">
          <p>
            Cервис <strong>«JustPay»</strong> – специализированный программно-технический
            комплекс, предназначенный для приема платежей клиентов через АИС
            «Расчет» (ЕРИП).
          </p>
          <p>
            Cервис <strong>«JustPay»</strong> является самым молодым проектом на рынке, но в этом
            наше преимущество. Мы открыты для внедрения нестандартных решений
            для Вашего бизнеса и готовы подстраиваться под Ваши требования.
          </p>
          <p>
            Cервис <strong>«JustPay»</strong> обеспечивает взаимодействие в режиме online с
            информационной системой производителя услуг посредством
            использования специализированных программных интерфейсов (API).
          </p>
          <p className="alert alert-info">
            Ознакомиться с информацией о:
            <ul>
              <li> условиях подключения к сервису <strong>«JustPay»</strong></li>
              <li> принципах оказания услуги</li>
              <li> способах обмена информацией</li>
              <li> требованиях по оказанию платежных услуг</li>
            </ul>
            можно в следующих документах:{" "}
            <a href={doc1} target="_blank" rel="noreferrer">
              <span style={{ color: "blue", paddingLeft: "5px" }}>
                "Общие условия участия"
              </span>
            </a>
            ,{" "}
            <a href={doc2} target="_blank" rel="noreferrer">
              <span style={{ color: "blue", paddingLeft: "5px" }}>
                Договор присоединения.
              </span>
            </a>
          </p>
          <p>
            Стоимость наших услуг оговаривается отдельно и зависит от специфики
            решаемых задач. За подключение к системе АИС «Расчет» плата не
            взимается.
          </p>
        </div>
      </div>
    </>
  );
};

export default JustPay;
