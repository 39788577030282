import React from 'react';
import { services } from '../../../constants';
import SectionHeader from '../sectionHeader';
import { Zoom } from 'react-awesome-reveal';
const Services = () => {
  return (
    <>
      <SectionHeader>Наши услуги</SectionHeader>
      <div className="row text-center">
        {services.map(({ icon, label }) => {
          return (
            <div className="col-md-4" key={label}>
              <Zoom  triggerOnce>
                <span className="fa-stack fa-4x">
                  <i className="fas fa-circle fa-stack-2x text-primary"></i>
                  <i className={`fas ${icon} fa-stack-1x fa-inverse`}></i>
                </span>
                <p className="text-muted">{label}</p>
              </Zoom>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Services;
