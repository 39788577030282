import React from 'react';
import Footer from './components/footer';
import Header from './components/header';
import PageSections from './components/page-sections';
import { Element } from 'react-scroll';

const App = () => {
  return (
    <div className="App">
      <Element name={'header'}>
        <Header />
      </Element>

      <PageSections />
      <Footer />
    </div>
  );
};

export default App;
