import React from 'react';
import SectionHeader from '../sectionHeader';

const Company = () => {
  return (
    <>
      <SectionHeader>О компании</SectionHeader>
      <div className="row">
        <div className="col-lg-12">
          <p>Компания "Глобо-Центр" основана в 1991 году.</p>
          <p>
            Мы занимаемся разработкой, внедрением и сопровождением собственных
            продуктов.
          </p>
          <p>
            В настоящее время нашим основным направлением является автоматизация
            страховой деятельности.
          </p>
          <p>
            Мы обладаем широким перечнем комплексных решений в этой области.
          </p>
          <br />
        </div>
      </div>
    </>
  );
};

export default Company;
