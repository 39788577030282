import React from 'react';

const Section = ({ bgColor = '', name, children }) => {
  return (
    <section className={`page-section ${bgColor}`} id={name}>
      <div className="container">{children}</div>
    </section>
  );
};

export default Section;
