export const scrollToElement = (scroller, element) => {
  scroller.scrollTo(element, {
    duration: 1000,
    delay: 100,
    smooth: true,
    offset: -10,
  });
};

export const addModalOverflowBodyHidden= () => {
  document.body.classList.add('modal-open');
};
export const clearModalOverflowBodyHidden = () => {
  document.body.classList.remove('modal-open');
};
